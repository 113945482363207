import mapValues from 'lodash/mapValues';
import type { CSSObject, DefaultTheme } from 'styled-components';
import { sx2CssTheme, SxStyleProp } from '@hotelplan/libs.sx';

const baseColors = {
  white: '#fff',
  black: '#000',
  gray: [
    '#fff',
    '#E1E1E1',
    '#EBEFF0',
    '#DDE5E7',
    '#EBEBEB',
    '#DDDDDD',
    '#F4F4F4',
    '#333',
    '#999999',
    '#e6e6e6',
  ],
};
const MAX_MOBILE_WIDTH = 1023;
const MIN_TABLET_DEVICE_WIDTH = 1024;
const MIN_DESKTOP_DEVICE_WIDTH = 1024;

const breakpoints = [
  `${MIN_TABLET_DEVICE_WIDTH}px) and (min-device-width: ${MIN_TABLET_DEVICE_WIDTH}px`,
  `${MIN_DESKTOP_DEVICE_WIDTH}px) and (min-device-width: ${MIN_DESKTOP_DEVICE_WIDTH}px`,
];

const MEDIA_MOBILE = `@media screen and (max-width: ${MAX_MOBILE_WIDTH}px)`;
const MEDIA_TABLET = `@media screen and (min-width: ${breakpoints[0]})`;
const MEDIA_DESKTOP = `@media screen and (min-width: ${breakpoints[1]})`;

const media = {
  mobile: MEDIA_MOBILE,
  tablet: MEDIA_TABLET,
  desktop: MEDIA_DESKTOP,
};

const fontSizes = [13, 16, 20, 24, 32, 40, 48, 64].map(n => n + 'px');

export const spaces: number[] = [0, 4, 8, 16, 24, 32, 40, 64, 128, 256];

const space = spaces.map(n => n + 'px');

export const FONT_FAMILY = '"Arial",sans-serif';

const colors: DefaultTheme['colors'] = {
  primary: '#0071b9',
  interactionPrimary: '#005D99',
  secondary: '#f60',
  white: baseColors.white,
  black: baseColors.black,
  warning: '#f60',
  success: '#00653B',
  error: '#E93223',
  defaultText: baseColors.black,
  lightGreyFirst: '#dcdcdc',
  lightGreySecond: '#EBEFF0',
  lightGreySixth: baseColors.gray[6],
  borderGray: '#bbb',
  textGray: '#666',
  modalShadow: 'rgba(0, 0, 0, 0.8)',
  background: 'white',
  backgroundGray: '#f4f4f4',
  backgroundDarkGray: '#959595',
  borderColor: '#ccc',
  accentColor: '#ccc',
  hoverGray: '#eee',
  hoverGraySecond: baseColors.gray[9],
  facebookButtonBackground: '#3B5998',
  facebookIconBackground: '#263C68',
  googleButtonBackground: '#F44336',
  googleIconBackground: '#C53126',
  darkGrey: baseColors.gray[7],
  meinKontoSuccess: '#1C6323',
  meinKontoError: '#CC0000',
  brandBlue: '#1476C6',
  grayMiddle: baseColors.gray[8],
};

const shadows: DefaultTheme['shadows'] = {
  blockShadow: '0 4px 7px 0 rgba(0,0,0,0.1)',
};

const transition: DefaultTheme['transition'] = {
  fast: 'all 0.2s ease-in',
  slow: 'all 0.3s ease-in',
};

const radii: DefaultTheme['radii'] = {
  default: '5px',
  circle: 99999,
};

const icons: DefaultTheme['icons'] = {
  xs: {
    width: '14px',
    height: '14px',
  },
  sm: {
    width: '17px',
    height: '17px',
  },
  md: {
    width: '20px',
    height: '20px',
  },
  lg: {
    width: '24px',
    height: '24px',
  },
  xlg: {
    width: '30px',
    height: '30px',
  },
  xxlg: {
    width: '40px',
    height: '40px',
  },
};

const basicTheme = Object.freeze({
  breakpoints,
  media,
  // mediaQueries,
  colors,
  space,
  fontSizes,
  radii,
  transition,
  shadows,
  icons,
});

const sx = (sxp: SxStyleProp) => sx2CssTheme(basicTheme, sxp);

type MappedToCss<T extends object> = {
  [key in keyof T]: CSSObject;
};

const sxGroup = <T extends Record<string, SxStyleProp>>(
  groups: T
): MappedToCss<T> => mapValues(groups, sx);

const ratingTextStyles = {
  hyphens: 'auto',
  flex: '0 12 auto',
  wordBreak: 'break-word',
  mr: 2,
  color: 'textGray',
  letterSpacing: 'normal',
};

const theme = Object.freeze<DefaultTheme>({
  ...basicTheme,
  shareForm: sxGroup({
    detailsContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: [2, 3],
      pb: [2, 3],
      '.title': {
        fontSize: '14px',
        fontWeight: 'bold',
        color: 'darkGrey',
        fontFamily: 'Arial',
      },
    },
    detailsImage: {
      height: '170px',
    },
    detailsSocials: {
      gap: 3,
      svg: {
        display: 'block',
        width: '30px',
        height: '30px',
      },
      mb: [2, 0],
    },
  }),
  text: {
    groupHeading: {
      fontSize: '22px',
      [media.mobile]: {
        fontSize: '18px',
      },
    },
    textBlockWrap: sx({
      mx: [null, 'auto'],
      '.text-block-text': {
        fontSize: ['14px', 0],
        padding: 3,
        border: '1px solid #bbb',
        borderLeft: ['none', '1px solid #bbb'],
        borderRight: ['none', '1px solid #bbb'],
        mb: '20px',
        mt: [3, 4],
      },
    }),
    richText: sx({
      p: {
        mb: 3,
        lineHeight: '1.8',
        fontSize: ['14px', 0],
      },
      a: {
        color: 'primary',
        display: 'inline',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
      ul: {
        lineHeight: '1.8',
        mb: 2,
        li: {
          listStyle: 'disc',
          ml: ['20px', '24px'],
        },
      },
      ol: {
        lineHeight: '1.8',
        mb: 2,
        li: {
          listStyle: 'decimal',
          ml: ['20px', '23px'],
        },
      },
      h1: {
        fontSize: [2, '26px'],
        fontFamily: '"Helvetica Neue", sans-serif',
        color: 'secondary',
        mb: 2,
      },
      h2: {
        fontFamily: '"Helvetica Neue", sans-serif',
        fontSize: ['18px', '22px'],
        mb: 2,
        color: 'secondary',
      },
      h3: {
        fontFamily: '"Helvetica Neue", sans-serif',
        fontSize: 1,
        mb: 2,
        color: 'secondary',
      },
      h4: {
        fontFamily: '"Arial", sans-serif',
        fontSize: [1, '18px'],
        color: 'primary',
        mb: 2,
      },
      h5: {
        fontFamily: '"Arial", sans-serif',
        fontSize: 1,
        mb: 2,
      },
      h6: {
        fontFamily: '"Arial", sans-serif',
        fontSize: '14px',
        mb: 2,
      },
    }),
    accent: {
      fontFamily: '"Helvetica Neue", sans-serif',
    },
  },
  link: sxGroup({
    linkBtn: {
      background: 'primary',
      fontFamily: '"Helvetica Neue", sans-serif',
      color: 'white',
      padding: ['9px 10px', '7px 10px'],
      '&:active': {
        filter: 'brightness(0.9)',
      },
    },
  }),
  tabs: sxGroup({
    tabControlsWrap: {
      borderColor: colors.lightGreySecond,
      flexGrow: 1,
      position: 'relative',
      border: 'none',
      '.checked': {
        borderBottomColor: 'white',
        color: 'secondary',
        background: 'white',
      },
    },
    tabControl: {
      fontFamily: '"Helvetica Neue", sans-serif',
      fontSize: [1, '18px'],
      background: 'linear-gradient(to bottom,#fff 0,#e7e7e7 100%)',
      color: 'primary',
      border: '1px solid #bbb',
      borderRight: ['none', '1px solid #bbb'],
      flexDirection: ['column', 'row'],
      justifyContent: 'center',
      alignItems: 'center',
      height: ['45px', '38px'],
      flexGrow: [1, 0],
      letterSpacing: ['0.3px', '0.5px'],
      transition: 'fast',
      [media.mobile]: {
        textAlign: 'center',
        lineHeight: 1.1,
        px: 1,
      },
      '.icon-wrap': {
        display: 'flex',
        mb: [1, 0],
        mr: [null, '10px'],
      },
      '.tab-label': {
        whiteSpace: [null, 'nowrap'],
      },
    },
  }),
  forms: sxGroup({
    label: {
      pl: ['5px'],
      color: 'secondary',
      mb: [1, 2],
      span: {
        ml: 1,
      },
    },
    registrationForm: {
      '.error-message-wrap': {
        fontSize: ['14px', 0],
        my: 3,
        '.account-already-exists-error': {
          color: 'error',
        },
      },
      '.checkbox-input-wrap': {
        mb: 3,
        '.label': {
          color: 'initial',
          pl: 0,
          fontWeight: 'normal',
        },
      },
      '.submit-button-wrapper': {
        display: 'flex',
        flexWrap: 'wrap',
        gap: [2, 3],
        flexDirection: ['column', 'row-reverse'],
        justifyContent: 'space-between',
        alignItems: 'center',
        p: {
          fontSize: 0,
        },
        '.form-submit-button': {
          width: ['100%', '140px'],
          marginLeft: 'auto',
        },
        '.registration-login-link': {
          fontSize: 1,
        },
      },
      backgroundColor: 'white',
      maxWidth: '770px',
      p: ['20px 24px', '30px'],
      mx: 'auto',
      mb: [null, '60px'],
      border: '1px solid',
      borderColor: 'borderColor',
      borderRadius: 'default',
      boxShadow: [null, 'blockShadow'],
      '.label': {
        color: 'textGray',
        fontSize: ['14px', 0],
        fontWeight: 'bold',
      },
      '.radio-group-container': {
        pt: 0,
      },
      '.radio-group-input': {
        div: {
          display: 'flex',
          flexDirection: 'row',
          mb: 3,
        },
        '.label': {
          mr: 4,
          pl: 0,
        },
      },
      '.text-input': {
        marginBottom: 3,
        '.input-icon': {
          display: 'none',
        },
      },
      '.error': {
        mt: -2,
        mb: 2,
        fontSize: ['14px', 0],
        color: 'error',
      },
    },
    emailForm: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: [null, '500px'],
      pb: [null, '30px'],
      '.error': {
        fontSize: '14px',
      },
      '.name-fields, .email-fields': {
        display: 'flex',
        flexDirection: ['column', 'row'],
        gap: [0, 3],
        '.input': {
          height: '44px',
        },
      },
      '.gender-select': {
        position: 'relative',
        width: ['100%', 'calc(100% / 2 - 10px)'],
        ':after': {
          position: 'absolute',
          content: '""',
          borderRight: '1px solid',
          borderColor: 'borderGray',
          height: ['34px', '28px'],
          top: ['20px', '19px'],
          right: '30px',
        },
        '.chevron': {
          bottom: ['35px', '32px'],
          right: ['11px', '10px'],
          width: '10px',
          height: '10px',
        },
        '.input': {
          mb: 4,
        },
        '.status-icon': {
          display: 'none',
        },
      },
      '.full-input': {
        width: '100%',
        mb: [3, 4],
        '.error': {
          fontSize: '14px',
          color: 'error',
        },
      },
      '.input': {
        pl: '10px',
        fontWeight: 'normal',
        '& .status-icon': {
          display: 'none',
        },
      },
      label: {
        fontSize: '14px',
        fontWeight: [null, 'bold'],
        color: 'darkGrey',
        mb: 1,
      },
      submitButtonWrapper: {
        display: 'flex',
        flexDirection: 'column',
        p: {
          lineHeight: ['1.2', '1.8'],
          fontSize: 0,
          color: 'textGray',
          '.privacy-link': {
            color: 'secondary',
            display: 'block',
          },
        },
        '@media screen and (max-width: 1000px) and (orientation: landscape)': {
          '.form-submit-button': {
            maxWidth: '70%',
          },
        },
      },
    },
    unsubscribeForm: {
      form: {
        label: {
          fontSize: 0,
          fontWeight: 'bold',
          color: 'defaultText',
          mb: [2, 0],
        },
        '.privacy-link-text': {
          display: 'none',
        },
        '.input': {
          color: 'textGray',
          mb: [3, '0px'],
          pl: '10px',
          '.icon': {
            display: 'none',
          },
        },
      },
      checkboxes: {},
    },
    customSelectControl: {
      '> div:after': {
        position: 'absolute',
        content: '""',
        borderRight: '1px solid',
        borderColor: 'borderGray',
        height: ['34px', '28px'],
        top: 0,
        right: '30px',
      },
      position: 'relative',
      '.chevron': {
        bottom: ['12px', '8px'],
        right: ['11px', '10px'],
        width: '10px',
        height: '10px',
      },
      '.input': {
        pl: 1,
        pr: 5,
      },
    },
    customCheckboxControl: {
      height: ['20px', '16px'],
      width: ['20px', '16px'],
      position: 'relative',
      border: '1px solid',
      borderColor: 'borderColor',
      borderRadius: '3px',
      order: -1,
      marginRight: [2, '10px'],
      backgroundColor: 'white',
      flexShrink: 0,
      '&::after': {
        content: "''",
        position: 'absolute',
        opacity: 0,
        left: ['6px', '5px'],
        top: ['-7px', '-6px'],
        width: ['11px', '9px'],
        height: ['23px', '18px'],
        border: '1px solid',
        borderColor: 'primary',
        borderWidth: '0 3px 3px 0',
        transform: 'rotate(40deg)',
      },
    },
    submitButtonWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      mt: [0, 3],
      [media.mobile]: {
        alignItems: 'flex-end',
      },
      p: {
        lineHeight: ['1.2', '1.8'],
        color: 'textGray',
        '.privacy-link': {
          '&:hover': {
            textDecoration: 'underline',
          },
        },
      },
    },
  }),
  greetings: sx({
    color: baseColors.gray[7],
    fontSize: [3, '28px'],
    mb: [3, 0],
    px: [2, 0],
    fontFamily: '"Helvetica Neue", sans-serif',
    '.name': {
      pl: 2,
    },
  }),
  inputs: sxGroup({
    numericControlWrap: sx({
      display: 'flex',
      flexShrink: '0',
      justifyContent: 'space-between',
      border: '1px solid #bbb',
      borderRadius: 'default',
      '.input': {
        background: 'transparent',
        width: ['40px', '30px'],
        textAlign: 'center',
        fontSize: ['14px', 0],
        fontWeight: 'bold',
        borderLeft: '1px solid #bbb',
        borderRight: '1px solid #bbb',
      },
    }),
    numericControl: sx({
      px: ['10px', 1],
      py: ['12px', '6px'],
      color: '#999',
      '.icon': {
        height: ['16px', '16px'],
        width: ['20px', '20px'],
      },
      letterSpacing: 'initial',
      ':disabled': {
        '.icon': {
          opacity: 0.3,
        },
        '&:hover, &:focus': {
          color: 'initial',
        },
      },
    }),
    baseInput: {
      border: '1px solid',
      borderColor: 'borderGray',
      borderRadius: 'default',
      width: '100%',
      fontSize: 0,
      color: baseColors.gray[7],
      height: ['35px', '29px'],
      pl: 1,
      background: 'transparent',
      '::placeholder': {
        color: 'textGray',
      },
      letterSpacing: 'inherit',
      ':disabled': {
        background: colors.lightGreyFirst,
        cursor: 'default',
      },
      ':hover': {
        cursor: 'pointer',
      },
    },
    baseInputIcon: sx({
      right: ['10px', '7px'],
      zIndex: '11',
      cursor: 'pointer',
    }),
    hasIconMargin: sx({
      pr: [3, 4],
    }),
    select: {
      border: '1px solid',
      borderColor: 'borderGray',
      borderRadius: 'default',
      backgroundColor: 'transparent',
      width: '100%',
      height: ['35px', '29px'],
      appearance: 'none',
      fontSize: 0,
      color: baseColors.gray[7],
      '::-ms-expand': {
        display: 'none',
      },
      ':hover': {
        cursor: 'pointer',
        background: colors.hoverGraySecond,
        option: {
          background: 'white',
        },
      },
      pl: 2,
    },
    success: {
      '.input': {
        border: '1px solid',
        borderColor: 'success',
      },
    },
    error: {
      '.input': {
        border: '1px solid',
        borderColor: 'error',
      },
    },
    searchControlField: {
      position: 'relative',
      '.label': {
        display: 'none',
      },
      input: {
        height: ['40px', '30px'],
        pr: ['40px', '30px'],
        pl: '10px',
        color: baseColors.gray[7],
        background: 'white',
        '&:placeholder': {
          color: 'textGray',
        },
        '&:hover': {
          background: '#e6e6e6',
        },
      },
      '.autocomplete-dropdown': {
        input: {
          background: 'white',
          borderTopRightRadius: '0px',
          borderBottomRightRadius: '0px',
          borderRight: 'none',
        },
      },
      '.icon': {
        height: ['20px', '16px'],
        width: ['20px', '16px'],
        color: '#999',
      },
      '&.travelDestinationInput': {
        '.icon': {
          color: 'primary',
        },
        '&:after': {
          content: '""',
          position: 'absolute',
          height: ['40px', '30px'],
          width: '1px',
          top: '0px',
          right: ['40px', '30px'],
          background: '#bbb',
          display: 'block',
          zIndex: '9',
        },
      },
    },
  }),
  autocomplete: sxGroup({
    inputWrapStyles: {
      input: {
        boxShadow: 'inset 1px 1px 3px rgb(0 0 0 / 35%)',
        background: 'white',
        width: '100%',
        borderRadius: '5px 0 0 5px',
        borderRight: 'none',
        [media.tablet]: {
          height: '30px',
        },
      },
    },
    loadMoreButton: {
      ':hover': {
        textDecoration: 'underline',
        textDecorationColor: colors.interactionPrimary,
        color: colors.interactionPrimary,
      },
    },
  }),
  buttons: sxGroup({
    primary: {
      fontFamily: '"Helvetica Neue", sans-serif',
      color: 'white',
      backgroundColor: 'primary',
      padding: ['9px 10px', '7px 10px'],
      fontWeight: '400',
      letterSpacing: '0.3px',
      '&:disabled': {
        backgroundColor: 'borderGray',
        color: 'darkGrey',
      },
      '&:active': {
        filter: 'brightness(0.9)',
      },
    },
    iconBtn: {
      backgroundColor: 'transparent',
      padding: space[1],
      fontSize: '0px',
      color: 'inherit',
      letterSpacing: 'initial',
      lineHeight: 1.3,
      svg: {
        margin: 0,
      },
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    btnWithIcon: {
      display: 'flex',
      alignItems: 'center',
      width: ['100%', 'auto'],
      fontSize: 1,
      px: '10px',
      marginLeft: 'auto',
      gap: 2,
      '.button-text': {
        width: '100%',
      },
      '.icon': {
        ml: 'auto',
        order: 1,
        width: '14px',
        height: '14px',
      },
    },
    allOffers: {
      display: 'flex',
      flexDirection: 'row-reverse',
      alignItems: 'center',
      gap: '8px',
      textTransform: 'uppercase',
      '.icon': {
        order: 'unset',
        width: '14px',
        height: '14px',
      },
    },
    burgerButton: {
      display: [null, 'none'],
      width: '36px',
      height: '36px',
      mr: '10px',
    },
    switchButton: {
      wrapper: {
        width: '100%',
        position: 'relative',
        height: ['40px', '30px'],
        display: 'flex',
        zIndex: 2,
        outline: `1px solid ${colors.grayMiddle}`,
        outlineOffset: '-1px',
        borderRadius: '4px',
        backgroundColor: 'white',
        overflow: 'hidden',
        color: 'textGray',
        'input + label': {
          position: 'relative',
          zIndex: 1,
          height: '100%',
          px: 3,
          py: 2,
          color: colors.grayMiddle,
          justifyContent: 'center',
          '&:hover': {
            color: 'textGray',
          },
        },
        'input:checked + label': {
          color: 'primary',
          border: `1px solid`,
          borderColor: 'primary',
          '&:hover': {
            background: 'transparent',
          },
        },
      },
      label: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: ['40px', '30px'],
        flexBasis: '50%',
        textAlign: 'center',
        alignSelf: 'center',
        py: 1,
        px: 2,
        cursor: 'pointer',
        fontSize: [1, 0],
        fontWeight: 700,
      },
    },
    blueGradientButton: {
      border: '1px solid #0071b9',
      background: 'linear-gradient(to bottom, #66aad5 0%, #0071b9 100%)',
      boxShadow: '1px 0px 2px rgb(203 200 194 / 25%)',
      borderRadius: 'default',
      p: 2,
      textTransform: 'uppercase',
      fontSize: '14px',
      fontWeight: 700,
      '&:hover': {
        background: 'linear-gradient(to bottom, #999999 0%, #666 100%)',
        borderColor: 'textGray',
      },
    },
    grayGradientButton: {
      border: '1px solid #cccccc',
      background: 'linear-gradient(to bottom, #ffffff 0%, #e7e7e7 100%)',
      color: '#0071b9',
      fontFamily: '"Helvetica Neue", sans-serif',
      fontSize: 2,
      borderRadius: 'default',
      p: 0,
      minWidth: '28px',
      '&:hover': {
        background: 'linear-gradient(to bottom, #fefefe 0%, #cecece 100%)',
      },
      '&:disabled': {
        color: 'textGray',
        cursor: 'default',
      },
    },
    linkBtn: {
      background: 'transparent',
      fontFamily: '"Helvetica Neue", sans-serif',
      color: 'primary',
      fontSize: ['14px', 0],
      p: 0,
      '&:active': {
        filter: 'brightness(0.9)',
      },
    },
  }),
  metaModules: sxGroup({
    cookies: {
      text: {
        marginRight: 'auto',
        marginLeft: 'auto',
        fontSize: ['14px', 0],
        lineHeight: 1.2,
        py: '10px',
        pl: ['10px', 0],
        a: {
          textDecoration: 'underline',
          fontWeight: 'bold',
        },
      },
      acceptButton: {
        backgroundColor: 'transparent',
        ml: 0,
        fontSize: '0px',
        lineHeight: 1,
        '&:after': {
          content: '"✕"',
          fontSize: '20px',
        },
      },
    },
  }),
  notifications: sxGroup({
    notificationInfo: {
      display: 'flex',
      flexShrink: 0,
      alignItems: 'center',
      borderRadius: '0px',
      py: 1,
      color: 'primary',
      a: {
        textDecoration: 'underline',
      },
      'a:hover': {
        color: 'secondary',
        textDecoration: 'underline',
      },
      height: '32px',
      overflow: 'hidden',
      pl: [null, '10px'],
      img: {
        width: '16px',
        height: '16px',
      },
    },
    notificationInfoContent: {
      '*, p': {
        fontSize: 1,
      },
    },
    mantainanceNotification: {
      maxWidth: '100%',
      position: 'relative',
      borderRadius: '0px',
      border: '0px',
      bottom: 0,
      '.notification-heading, .notification-body': {
        mx: 'auto',
        px: ['10px', 'calc((100% - 978px) / 2)'],
        color: 'white',
        fontSize: 1,
        '.icon': {
          width: '20px',
          height: '20px',
        },
      },
      '.notification-heading': {
        '& .warning-notification-title': {
          maxWidth: '1028px',
          marginBottom: '0px',
          mr: 3,
          color: 'white',
          fontFamily: '"Helvetica Neue", sans-serif',
          fontSize: [2, '26px'],
        },
      },
      '.notification-body': {
        pt: '0px',
        fontSize: ['14px', 0],
        a: {
          color: 'primary',
          display: 'inline',
          '&:hover': {
            textDecoration: 'underline',
          },
        },
      },
    },
  }),
  header: sxGroup({
    wrap: {
      background: [
        'white',
        'linear-gradient(to bottom,#fff 0,#fff 50%,#eee 100%)',
      ],
      boxShadow: [null, '-1px 5px 7px -3px rgb(0 0 0 / 20%)'],
      position: 'relative',
    },
    logo: {
      flexShrink: 0,
      ml: ['10px', 0],
      my: ['10px', 0],
      alignSelf: [null, 'flex-start'],
      paddingTop: [null, '18px'],
      img: {
        maxWidth: '102px',
        height: '32px',
        display: 'block',
        [media.tablet]: {
          maxWidth: '166px',
          height: '55px',
        },
      },
    },
    body: {
      display: 'flex',
      flexWrap: 'wrap',
      maxWidth: '1028px',
      minHeight: ['50px', '105px'],
      margin: '0 auto',
      alignItems: ['center', 'flex-start'],
      boxShadow: ['0 6px 5px -5px rgb(50 50 50 / 50%)', 'none'],
      padding: [null, `0 ${space[4]}`],
    },
  }),
  footer: {
    heading: {
      fontSize: fontSizes[3],
      marginBottom: space[2],
      textAlign: 'center',
      letterSpacing: '0.8px',
      [media.tablet]: {
        fontSize: '25px',
        marginBottom: space[3],
        textAlign: 'start',
      },
    },
  },
  brandLogos: sxGroup({
    items: {
      px: [2, 0],
      py: [2, '30px'],
      mb: '0px',
      borderBottom: 'none',
    },
    imageLink: {
      flexShrink: 0,
      maxWidth: '103px',
      img: {
        filter: 'grayscale(1)',
        '&:hover': {
          filter: 'none',
        },
        maxHeight: '35px',
      },
      mx: [3, 4],
      '&:first-child': {
        ml: '0px',
      },
      '&:last-child': {
        mr: '0px',
      },
    },
  }),
  menu: sxGroup({
    nav: {
      fontSize: 2,
      fontFamily: '"Helvetica Neue", sans-serif',
      '&:hover': {
        color: '#f60',
      },
    },
    headerMenu: {
      width: ['100%', 'auto'],
      mb: [3, '0px'],
      flex: [1, '0 1 auto'],
    },
    navBarItem: {
      maxWidth: [null, '270px'],
      width: '100%',
      mr: [null, '40px'],
    },
    navBarLink: {
      py: ['20px', '12px'],
      px: [1, 2],
      borderTop: '1px solid',
      borderBottom: '1px solid',
      borderColor: 'borderColor',
      mb: '-1px',
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontSize: ['14px', 1],
      lineHeight: [null, '1.3'],
      '.icon': {
        color: 'primary',
      },
      textTransform: ['uppercase', 'initial'],
    },
    subLinksListWrap: {},
    subLinksList: {},
    menuLinkItem: {
      px: 3,
      py: 2,
      height: [null, 'auto'],
      '> a': {
        minHeight: [null, 'auto'],
      },
    },
    mobileMenuModal: {
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      boxShadow: '0 6px 5px -5px rgb(50 50 50 / 50%)',
      width: ['100%', '40%'],
      height: '100vh',
      backgroundImage: 'linear-gradient(#fff,#e7e7e7)',
      pt: 3,
      pb: 0,
      top: '50px',
      bottom: 0,
      overflowY: 'auto',
      nav: {
        a: {
          color: 'primary',
          textTransform: 'capitalize',
          fontSize: 1,
          fontWeight: 'bold',
          py: '0px',
          px: '10px',
          lineHeight: '38px',
          svg: {
            height: '15px',
          },
        },
      },
      '.mobile-nav-bar-list': {
        pb: '150px',

        'li a': {
          position: 'relative',
          color: 'primary',
          '&:after': {
            content: '""',
            position: 'absolute',
            top: '12px',
            right: '10px',
            display: 'block',
            width: '15px',
            height: '15px',
            backgroundImage: 'url(images/svg/chevron-long-right-blue.svg)',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
          },
        },

        '.navbar-sublink': {
          pl: 4,
          fontSize: 0,
          '.icon': {
            display: 'none',
          },
          '&:after': {
            width: '13px',
            height: '13px',
          },
        },
      },
    },
  }),
  product: sxGroup({
    productDescriptionWrap: {
      background: 'white',
      fontSize: '14px',
      li: {
        fontSize: 0,
      },
      '.block': {
        pb: 3,
        fontSize: [null, 0],
        h3: {
          fontSize: ['14px', 1],
          marginBottom: 1,
          color: baseColors.gray[7],
          fontFamily: 'Arial',
          fontWeight: 'bold',
        },
        'p:not(:last-child)': {
          mb: 2,
        },
        'p:last-child': {
          mb: 0,
        },
      },
    },
    featuresListWrapper: {
      pt: 3,
      pb: 2,
      '.features-list': {
        fontSize: [null, 3],
      },
      '.feature-item': {
        display: 'flex',
        gap: 2,
        fontSize: ['14px', 0],
        lineHeight: ['1.3', ''],
        mb: 1,
      },
      '.feature-icon-wrapper': {
        verticalAlign: [`sub`, `middle`],
        '.icon': {
          width: '16px',
          height: '16px',
        },
      },
    },
    featuresHeadingWrapper: {
      display: 'flex',
      alignItems: 'center',
      mb: 3,
      '.icon': {
        mr: 2,
        width: '25px',
        height: '25px',
      },
      '.features-heading': {
        fontSize: ['14px', '15px'],
        fontWeight: '600',
        fontFamily: 'Arial',
        textTransform: 'uppercase',
      },
    },
  }),
  modals: sxGroup({
    absoluteDropdownBox: {
      bg: 'background',
      top: '0',
      width: ['auto', '100%'],
      position: 'absolute',
      zIndex: '12',
    },
    notificationCardWrap: {},
    modalContentStyles: {
      flex: '1',
      flexDirection: 'column',
      p: 4,
      pb: 5,

      '> *': {
        flexShrink: '0',
      },
    },
    catalogErrorModal: {
      '.modal-wrapper': {
        width: '100%',
        maxWidth: '770px',
      },
      '.modal-shadow': {
        backgroundColor: colors.modalShadow,
      },
      '.modal-header': {
        color: 'white',
        background: colors.darkRed,
        '& .modal-title h2': {
          mr: 4,
          fontSize: ['18px', '22px'],
        },
      },
    },
    wideModalWrapper: {
      maxWidth: [null, '970px'],
      borderRadius: ['roundedTop', '0px'],
      height: [null, 'auto'],
      '.modal-header': {
        fontSize: ['18px', '22px'],
      },
    },
    wideModalHeader: {
      py: [2, '14px'],
      pl: ['20px', 4],
      pr: ['20px', 3],
    },
    actionlessModalWrapper: {
      maxWidth: [null, '970px'],
      borderRadius: 'default',
      mx: [4, 0],
      alignSelf: 'center',
    },
    forgotPasswordModal: {
      '.modal-wrapper': {
        width: '100%',
        maxWidth: '500px',
        position: 'relative',
        height: [null, 'auto'],
        borderRadius: ['roundedTop', '0px'],
        '.modal-header': {
          fontSize: [2, 3],
          pl: 4,
          pr: [4, 3],
        },
      },
      '.modal-body': {
        '.auth-form-text': {
          fontSize: [0, 1],
          lineHeight: 1.3,
        },
        '.form-submit-button': {
          mb: 0,
        },
        '.error': {
          fontSize: ['14px', 0],
          mt: 1,
        },
      },
      '.label': {
        fontSize: ['14px', 0],
        color: 'textGray',
      },
    },
    smallModalWrapper: {
      width: ['100%', '500px'],
      '.modal-body': {
        backgroundColor: baseColors.white,
        p: 4,
        fontSize: ['14px', 0],
      },
      '.modal-title': {
        mr: 4,
        fontSize: ['18px', '22px'],
      },
      '.modal-header': {
        pl: ['20px', 4],
        pr: ['20px', 3],
      },
    },
    commonModalBody: {
      px: [4, '40px'],
      pt: [4, '30px'],
      pb: [4, '40px'],
    },
    notificationCardWrapWithError: {},
    failureModal: {
      '.modal-wrapper': {
        width: '100%',
        maxWidth: '770px',
      },
      '.modal-shadow': {
        backgroundColor: colors.modalShadow,
      },
      '.modal-header': {
        color: 'white',
        background: colors.darkRed,
        '& .modal-title h2': {
          mr: 4,
          fontSize: ['18px', '22px'],
        },
      },
    },
    loadingModal: {
      '.modal-wrapper': {
        border: `1px ${colors.borderColor} solid`,
        borderRadius: 'default',
        color: 'secondary',
        p: '30px',
        pb: '20px',
        maxWidth: '400px',
        textAlign: 'center',
      },
      '.modal-header': {
        border: '1px solid transparent',
        fontSize: 3,
      },
    },
  }),
  catalogs: sxGroup({
    catalogsHeading: {
      my: [0, 4],
      mx: 3,
      color: 'secondary',
      [media.mobile]: {
        p: 2,
      },
    },
    catalogsOrderHeading: {
      mt: 0,
      mb: [0, 2],
      py: 2,
      px: [3, 0],
      color: 'secondary',
    },
    catalogFormSubmitButton: {
      position: 'relative',
      textTransform: 'uppercase',
      fontSize: 1,
      ml: 0,
      mb: 2,
      pr: 4,
      '.icon': {
        position: 'absolute',
        right: '5px',
        top: '9px',
        height: '14px',
        width: '14px',
      },
      justifyContent: 'center',
      '@media screen and (max-width: 1000px) and (orientation: landscape)': {
        width: '70%',
        '.form-submit-button': {
          width: '70%',
        },
      },
    },
    catalogForm: {
      fontSize: ['14px', 0],
      width: ['100%', '70%'],
      'fieldset+.error': {
        mt: -3,
      },
      px: [2, 0],
      '.status-icon': {
        display: 'none',
      },
      input: {
        '&::placeholder': {
          color: 'borderGray',
        },
        '& + .icon': {
          display: 'none',
        },
      },
      label: {
        color: ['textGray', baseColors.gray[7]],
        '&:not(.order-catalogs-checkbox)': {
          fontWeight: 'bold',
        },
      },
      '.error': {},
    },
    catalogsFormContent: {
      display: 'flex',
      mx: '-5px',
      flexWrap: 'wrap',
      mb: 2,
    },
    catalogsListWrap: {
      display: 'flex',
      justifyContent: 'flex-start',
      borderRadius: 0,
      borderColor: 'borderColor',
      [media.mobile]: {
        borderLeftStyle: 'none',
        borderRightStyle: 'none',
      },
      '.imageWrap': {
        flexShrink: 0,
      },
      '.content': {
        '.catalog-links': {
          display: 'flex',
          justifyContent: ['flex-end', 'space-between'],
          flexDirection: ['row-reverse', 'column-reverse'],
        },
        '.link-wrapper': {
          mr: ['auto', 0],
        },
        p: 2,
        fontSize: fontSizes[0],
        color: colors.textGray,
        '.validity-dates': {
          mb: [1, 3],
        },
        a: {
          color: colors.secondary,
          '&:hover': {
            textDecoration: 'underline',
          },
          svg: {
            display: 'none',
          },
        },
      },
    },
    catalogList: {
      display: 'flex',
      flexWrap: 'wrap',
      mb: [0, 5],
      px: [0, 3],
      '> div': {
        flex: ['1 0 345px', '0 0 345px'],
        mr: [0, 3],
        mb: [2, 3],
      },
    },
    catalogsOrderedCatalogsList: {
      display: 'flex',
      flexWrap: 'wrap',
      mb: [2, 4],
      pt: [0, 3],
      '> div': {
        flex: ['1 0 345px', '0 0 345px'],
        mr: [0, 3],
        mb: [2, 3],
      },
    },
    catalogCardWrap: {
      a: {},
      '.catalogs-link-image': {
        width: 'auto',
        height: ['120px', '155px'],
      },
      '.content a': {
        width: 'auto',
      },
    },
    catalogsDownloadLinkCss: {},
    catalogsOrderLayoutWrap: {
      borderTop: `1px ${colors.borderColor} solid`,
      px: [0, 3],
      py: [2, 4],
      [media.mobile]: {
        borderTopStyle: 'none',
      },
    },
    catalogCardInfoFormWrap: {
      display: 'flex',
      border: `1px ${colors.borderColor} solid`,
      [media.mobile]: {
        borderLeftStyle: 'none',
        borderRightStyle: 'none',
      },
      borderColor: 'borderColor',
      p: 0,
      width: '100%',
      flexShrink: '0',
      '.imageWrap': {
        flexShrink: 0,
      },
    },
    catalogCardInfoImage: {
      display: 'block',
      height: ['120px', '155px'],
    },
    catalogCardInfoFormContent: {
      display: 'flex',
      position: 'relative',
      flexGrow: 1,
      flexDirection: 'column',
      justifyContent: 'space-between',
      '.wrapper': {
        width: '100%',
        fontSize: 0,
        color: colors.textGray,
        p: '10px',
      },
    },
    catalogDeleteButtonWrap: {
      position: 'absolute',
      top: 0,
      right: 0,
      lineHeight: 1,
      button: {
        background: 'linear-gradient(to bottom, #fefefe 0,#e7e7e7 100%)',
        borderColor: 'borderColor',
        borderWidth: '1px',
        borderBottomStyle: 'solid',
        borderLeftStyle: 'solid',
        borderBottomLeftRadius: 'default',
        p: 1,
        color: 'textGray',
        '&:hover': {
          background: 'linear-gradient(to bottom, #fefefe 0,#cccccc 100%)',
        },
      },
    },
  }),
  counter: {},
  climate: sxGroup({
    chartContainer: {
      height: 300,
    },
    chartsWrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      mb: [0, '90px'],
    },
    preparedChart: {
      pb: ['40px', 4],
      display: 'flex',
      flexDirection: ['column', 'row'],
      justifyContent: [null, 'space-between'],
      width: '100%',

      '.chart-label': {
        flex: [null, '1 1 180px'],
        fontFamily: '"Helvetica Neue", sans-serif',
        fontSize: '18px',
        pt: [1],
        pb: [2],
        mr: [3],
        borderBottom: [null, '1px solid #e4e4e4'],
        alignSelf: 'start',
      },

      '.chart-body': {
        flex: [null, '0 0 728px'],
      },

      '.chart-sub-label': {
        fontSize: 0,
      },
    },
  }),
  destinationAttributes: sxGroup({
    ratingTextStyles,
    destinationRatingStyles: {
      backgroundColor: 'secondary',
      height: ['16px', '17px'],
      width: ['16px', '17px'],
      borderRadius: 'circle',
      '&:not(:last-child)': {
        mr: 1,
      },
    },
    destinationRemainRatingStyles: {
      backgroundColor: 'transparent',
      borderColor: 'secondary',
      borderWidth: 1,
      borderStyle: 'solid',
    },
    destinationRatingSubStyles: {
      backgroundColor: 'secondary',
      height: ['16px', '17px'],
      width: ['16px', '17px'],
      borderRadius: 'circle',
      '&:not(:last-child)': {
        mr: 1,
      },
    },
    destinationRemainRatingSubStyles: {
      backgroundColor: 'transparent',
      borderColor: 'secondary',
      borderWidth: 1,
      borderStyle: 'solid',
    },
    attributeWrap: {
      py: 2,
      mb: '-1px',
      letterSpacing: '0.7px',
    },
    attributeItemWrap: {
      flex: 1,
      mb: 3,
      fontSize: [null, 3],
      lineHeight: 1,
      position: 'relative',
      '.icon': {
        width: '40px',
        height: '40px',
        mr: 3,
      },
      '.attributeRatingWrap': {
        position: 'absolute',
        ml: '57px',
        mt: '-20px',
      },
      '.attributeName': {
        ...ratingTextStyles,
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontSize: ['14px', '15px'],
        m: 0,
      },
    },
    attributeDetailsList: {
      fontSize: [null, 3],
      borderTop: '1px solid',
      borderBottom: '1px solid',
      borderColor: 'borderColor',
      my: 3,
      py: 2,
    },
    attributeDetailWrap: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontSize: 0,
      py: 1,

      '&:not(:last-child)': {
        mb: [2, 1],
      },

      '.attributeDetailName': {
        ...ratingTextStyles,
      },
      '.attributeRatingWrap': {
        display: 'flex',
        alignItems: 'center',
        mr: [0, 4],
      },
    },
    attributeDetailWrapMobile: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      pr: [0, 4],
      mb: 2,
      fontSize: ['14px', 1],

      '.attributeDetailName': {
        ...ratingTextStyles,
      },
      '.attributeRatingWrap': {
        display: 'flex',
        alignItems: 'center',
      },
    },
    attributeWrapMobile: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flex: 1,

      '.attributeName': {
        ...ratingTextStyles,
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontSize: ['14px', '15px'],
        display: 'flex',
        alignItems: 'center',
      },
      '.ratingWrap': {
        display: 'flex',
        flex: '0 0 auto',
        ml: 2,
      },
    },
  }),
  mediaGallery: sxGroup({
    mediaGalleryStyles: {
      '.slider-control-centerleft': {
        left: '25px!important',
      },
      '.slider-control-centerright': {
        right: '25px!important',
      },
    },
    mediaGalleryModal: {
      '.modal-body': {
        maxWidth: '1185px',
        width: '100%',
        mx: 'auto',
        p: 4,
        py: [4, '40px'],
      },
    },
  }),
  tooltip: {
    top: '-87%',
    left: '50%',
    display: 'block',
    transform: 'translateX(-50%)',
    background: 'white',
    borderRadius: radii.default,
    position: 'absolute',
    visibility: 'hidden',
    border: `1px solid lightgrey`,
    padding: '10px',
    fontSize: fontSizes[1],
    fontWeight: 'normal',
    zIndex: '10',
    ':after': {
      content: "''",
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderWidth: '8px 8px 0 8px',
      borderColor: 'white transparent transparent transparent',
      position: 'absolute',
      bottom: '-7px',
      left: '50%',
      transform: 'translateX(-50%)',
    },
    ':before': {
      content: "''",
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderWidth: '8px 8px 0 8px',
      borderColor: `lightgrey transparent transparent transparent`,
      position: 'absolute',
      bottom: '-8px',
      left: '50%',
      transform: 'translateX(-50%)',
    },
    '&.arrow-left': {
      ':after': {
        top: '50%',
        left: '-11px',
        bottom: 'auto',
        transform: 'translateY(-50%) rotate(90deg)',
      },
      ':before': {
        top: '50%',
        left: '-12px',
        bottom: 'auto',
        transform: 'translateY(-50%) rotate(90deg)',
      },
    },
    '&.arrow-right': {
      ':after': {
        top: '50%',
        left: 'auto',
        right: '-11px',
        bottom: 'auto',
        transform: 'translateY(-50%) rotate(-90deg)',
      },
      ':before': {
        top: '50%',
        left: 'auto',
        right: '-12px',
        bottom: 'auto',
        transform: 'translateY(-50%) rotate(-90deg)',
      },
    },
    '&.arrow-top': {
      ':after': {
        top: '-7px',
        bottom: 'auto',
        transform: 'translateX(-50%) rotate(180deg)',
      },
      ':before': {
        top: '-8px',
        bottom: 'auto',
        transform: 'translateX(-50%) rotate(180deg)',
      },
    },
    '.title': {
      background: colors.secondary,
      padding: '6px 12px',
      color: 'white',
      fontSize: '22px',
      whiteSpace: 'nowrap',
      borderRadius: `${radii.default} ${radii.default} 0 0`,
    },
    '.text': {
      fontFamily: 'Arial',
      padding: '10px',
      fontSize: '13px',
      fontWeight: '400',
      textAlign: 'left',
      color: colors.textGray,
      borderWidth: '0 1px 1px 1px',
      borderRadius: `0 0 ${radii.default} ${radii.default}`,
      borderColor: 'lightgrey',
      borderStyle: 'solid',
    },
  },
  travelRooms: sxGroup({
    tooltipWrapper: {
      position: 'absolute',
      width: '380px',
      '.tooltip': {
        visibility: 'visible',
        zIndex: 2,
        top: '-58px',
        right: '-105px',
        fontSize: 0,
        ':after': {
          top: '42px',
          left: '-11px',
          bottom: 'auto',
          transform: 'rotate(90deg)',
        },
        ':before': {
          top: '42px',
          left: '-12px',
          bottom: 'auto',
          transform: 'rotate(90deg)',
        },
      },
    },
    closeButton: {
      display: ['none', 'block'],
      backgroundColor: 'transparent',
      color: '#000',
      float: 'right',
      mt: '-10px',
      mr: '-12px',
      '.icon': {
        width: '15px',
        height: '15px',
      },
    },
    wrapper: {
      display: ['flex', 'grid'],
      gridTemplateColumns: [null, 'auto 1fr'],
      flexDirection: 'column',
      backgroundColor: 'background',
      gap: '20px 25px',
      mb: 3,
      '> *': {
        label: {
          fontSize: '14px',
          fontWeight: 'bold',
          mb: 1,
        },
      },
      '> *:first-child': {
        flexDirection: 'column',
        'grid-column': ['span 2', 'span 1'],
        alignItems: ['flex-start', 'center'],
      },
      '.room-1, .room-2, .room-3, .room-4': {
        '> *': {
          flexDirection: 'column',
        },
        display: 'flex',
        justifyContent: ['space-between', 'start'],
        flexWrap: 'wrap',
        gap: [null, '20px 25px'],
        'grid-column': ['span 2', 'span 1'],
      },
      '.room-2, .room-3, .room-4': {
        gridColumnStart: [null, 2],
        borderTop: ['1px solid #ddd', 'none'],
        pt: [4, 0],
      },
      '.rooms-label-block': {
        display: 'flex',
        alignItems: 'center',
        '.icon': {
          width: '14px',
          height: '14px',
          ml: 3,
        },
      },
      '.children-DOB-Wrapper': {
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        pt: [3, 1],
        '> *': {
          flexDirection: 'column',
        },
        width: ['100%', 'auto'],
        alignItems: 'end',
      },
      '.warning-notification, .warning-notification-children': {
        gridColumn: 'span 2',
        '.notification-heading': {
          display: 'none',
        },
        '.notification-body': {
          color: 'red',
          fontSize: 0,
          p: 0,
        },
      },
    },
    childrenDOBWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      ml: [null, '-10px'],
      '.childrenBirthTitle': {
        fontSize: ['12px', '10px'],
      },
      '.childrenBirthInput': {
        flex: '1 0 auto',
        width: ['165px', '130px'],
        '.input': {
          height: ['40px', '30px'],
          fontSize: ['12px', 0],
          px: 2,
          background: 'white',
          borderRadius: '5px 0 0 5px',
          '&::placeholder': {
            fontSize: '12px',
          },
          '&:hover': {
            background: colors.hoverGraySecond,
          },
        },
        '.action-button-wrapper': {
          height: ['40px', '30px'],
          mt: 1,
        },
        '.action-button': {
          borderRadius: '0 5px 5px 0',
          borderColor: '#bbb',
          width: ['40px', '30px'],
          svg: {
            color: colors.primary,
          },
        },
      },
    },
    manualTravelersInput: {},
    applyButton: {
      minHeight: 'min-content',
      float: 'right',
      pr: '26px',
      textTransform: 'uppercase',
      position: 'relative',
      '&:after': {
        content: '""',
        position: 'absolute',
        transform: 'translateY(-50%)',
        right: '8px',
        bottom: '3px',
        display: 'block',
        width: '10px',
        height: '14px',
        backgroundImage: 'url("/images/svg/chevron-long-right-white.svg")',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
    },
  }),
  travelDates: sxGroup({
    durationDropdownWrapper: {
      '.input': {
        width: [null, '100%'],
        fontSize: ['14px', 0],
        pl: 2,
        fontWeight: 'bold',
      },
      '.chevron': {
        bottom: ['16px', '10px'],
        right: ['13px', '8px'],
      },
    },
    travelDatesContainerCss: {
      width: '100%',
      overflow: 'auto',
      '.DayPicker-NavBar': {
        '.icon': {
          width: '18px',
          height: '18px',
          position: 'relative',
          top: '10px',
          color: 'white',
        },
      },
      '.DayPicker-NavBar button:nth-child(1) .icon, .DayPicker-NavBar button:nth-child(2) .icon':
        {
          top: '6px',
          height: '20px',
        },

      '.DayPicker': {
        width: '100%',
      },

      '.DayPicker-wrapper': {
        outline: 'none',
        pb: '0px',
      },

      '.DayPicker-Months': {
        flexDirection: 'column',
      },

      '.DayPicker-Month': {
        width: '100%',
        margin: 0,
      },

      '.DayPicker-Month .DayPicker-Caption div': {
        fontWeight: 'bold',
        fontSize: [2, '18px'],
      },

      '.DayPicker-Month .DayPicker-Caption': {
        textAlign: 'right',
        margin: 0,
        padding: 0,
      },

      '.DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside), .DayPicker-Day--inside-range:not(.DayPicker-Day--outside)':
        {
          backgroundColor: 'transparent',
          color: 'inherit',
        },

      '.DayPicker-Day': {
        borderRadius: 0,
        height: '36px',
        width: '40px',
        lineHeight: 1.1,
        outline: 'none',
      },

      '.DayPicker-Day--today': {
        fontWeight: 'normal',
      },

      '.DayPicker-Day--today--disabled': {
        color: 'lightGreyFirst',
      },

      '.DayPicker-Day--disabled': {
        color: 'lightGreyFirst',
      },

      '.DayPicker-Day--today:not(.DayPicker-Day--selected):not(.DayPicker-Day--outside)':
        {
          position: 'relative',
          color: 'white',
          zIndex: 1,
        },

      '.DayPicker-Day--today:not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):after':
        {
          content: '""',
          width: '28px',
          height: '28px',
          backgroundColor: 'secondary',
          borderRadius: '50%',
          display: 'block',
          position: 'absolute',
          zIndex: -1,
          // HACK: IE hack, transform is not working properly
          top: '4px',
          left: '8px',
        },

      '.DayPicker-Day--start:not(.DayPicker-Day--outside)': {
        borderTopLeftRadius: '4px !important',
        borderBottomLeftRadius: '4px !important',
        backgroundColor: 'black !important',
      },

      '.DayPicker-Day--end:not(.DayPicker-Day--outside)': {
        borderTopRightRadius: '4px !important',
        borderBottomRightRadius: '4px !important',
        backgroundColor: 'black !important',
      },

      '.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover':
        {
          backgroundColor: 'grey',
          color: 'white',
        },
    },
    bottomPanelCss: {
      display: 'none',
    },
    headerCss: {
      pt: [3, '20px'],
      position: ['fixed', 'relative'],
      top: ['55px', 'auto'],
      backgroundColor: 'background',
      left: 0,
      width: '100%',
      zIndex: 2,
    },
    headerGroupCss: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: ['column', 'row'],
      mr: [null, 4],
      mb: [1, '20px'],
      alignItems: [null, 'center'],
      flexBasis: [null, '50%'],
      gridColumn: ['span 2', ''],
      '.label-exact': {
        display: 'none',
      },
      '.label-flexible': {
        '& + div': {
          maxWidth: ['100%', '145px'],
        },
      },
      '.label-exact, .label-flexible': {
        fontSize: ['12px', '14px'],
        fontWeight: 'bold',
        mb: [1, 0],
      },
    },
    navbarControlCss: {
      position: 'absolute',
      width: '100%',
      color: 'inherit',
      'button:disabled': {
        color: 'lightGreyFirst',
        cursor: 'default',
        filter: 'brightness(50%)',
      },
    },
    weekDaysListCss: {
      textAlign: 'center',
      backgroundColor: 'backgroundGray',
    },
    travelDatesDesktop: {
      '.date-picker': {
        '.DayPicker-Months': {
          flexDirection: 'row',
          justifyContent: 'space-between',
        },
        '.DayPicker-Month .DayPicker-Caption': {
          textAlign: 'center',
          py: 2,
          fontFamily: '"Helvetica Neue",sans-serif',
          backgroundColor: 'secondary',
          color: 'white',
          borderRadius: 'default',
        },
        '.DayPicker-Weekday': {
          color: 'textGray',
          fontSize: '13px',
          fontWeight: 'bold',
        },
      },
      '.header': {
        display: 'flex',
        flexDirection: 'row',
      },
    },
    travelDatesMobile: {},
  }),
  responsiveMediaGallery: sxGroup({
    carouselWrapper: {
      preview: {
        position: 'relative',
        width: '100%',
        maxHeight: ['210px', '380px'],
        mb: [null, '60px'],
        ':after': {
          content: "''",
          display: 'block',
          width: '100%',
          paddingBottom: '54%',
          pointerEvents: 'none',
        },
        '.slider-list, .slider-slide': {
          height: '100% !important',
          width: '100% !important',
        },
        '.disable': {
          display: 'none',
        },
      },
    },
    carouselBody: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      '.slider-control-centerleft': {
        left: ['5px!important', '20px!important'],
        top: ['50%', '105%!important'],
        svg: {
          transform: 'rotate(180deg)',
        },
        zIndex: '1',
      },
      '.slider-control-centerright': {
        right: ['5px!important', '20px!important'],
        top: ['50%', '105%!important'],
        zIndex: '1',
      },
      '.slider-control-bottomright': {
        zIndex: 2,
      },
      '.paging-item button': {
        opacity: '1!important',
        border: '1px solid #bbadad!important',
      },
      '.paging-item.active button': {
        background: 'none!important',
        backgroundColor: '#f60!important',
        border: 'none!important',
      },
      '.dots-container': {
        gap: '10px',
        mx: 'auto!important',
        top: '7px!important',
      },
      '.slider-control-bottomcenter': {
        width: '100%',
        display: ['none', 'flex'],
        background: 'linear-gradient(to bottom,#fff 0,#e7e7e7 100%)',
        border: '1px solid #bbb',
        height: '35px',
        bottom: '-35px!important',
      },
    },
    chevron: {
      cursor: 'pointer',
      width: ['25px', '23px'],
      height: ['25px', '23px'],
      display: 'block',
      color: ['white', 'primary'],
    },
  }),
  doubleRange: sxGroup({
    rangeInputHeader: {
      pt: [3, 0],
      pb: [2, 0],
      px: [3, 1],
      '.price-range-input-label': {
        mb: 2,
        fontSize: [1, 2],
        color: 'secondary',
      },
    },
    rangeInputWrapper: {
      '.range-input-text': {
        color: 'textGray',
        fontSize: '14px',
        ml: -1,
      },
    },
    sliderWrapper: {
      position: 'relative',
      height: '32px',
      '.movingTooltip': {
        position: 'absolute',
        p: 3,
        minWidth: '170px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px solid #999',
        boxShadow: '0 1px 7px rgb(0 0 0 / 50%)',
        backgroundColor: 'white',
        visibility: 'visible',
      },
    },
  }),
  checkout: sxGroup({
    checkoutWaitingScreenWrapper: {
      h3: {
        maxWidth: ['100%', '80%'],
        mx: [3, 'auto'],
        fontSize: [2, '26px'],
        textAlign: 'center',
        lineHeight: '1.5',
        mb: ['12px', '28px'],
        color: 'secondary',
        '@media screen and (max-width: 390px)': {
          mb: 2,
          mt: '-10px',
        },
      },
      span: {
        height: '6px',
        flexGrow: 1,
        marginLeft: ['-40px', '-50px'],
      },
      '.product-details': {
        display: 'flex',
        flexDirection: ['column', 'row'],
        gap: ['0px', 3],
        justifyContent: 'center',
        alignItems: ['flex-start', 'baseline'],
        borderBottom: `1px solid ${baseColors.gray[1]}`,
        mb: [3, 4],
        pb: [3, 4],
        px: 3,
        color: colors.darkGray,
        fontWeight: 'normal',
        '.product-name': {
          fontSize: [2, 3],
          [media.mobile]: {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            width: '90vw',
          },
        },
        '& div': {
          color: 'textGray',
          fontSize: [1, 2],
          fontWeight: 'normal',
          mr: 1,
          '&:not(:last-child):after': {
            content: "'|'",
            ml: 1,
          },
        },
        '.breadcrumb-list': {
          [media.mobile]: {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            width: '90vw',
          },
        },
      },
      '.loading-animation-wrapper': {
        textAlign: 'center',
        lineHeight: '0',
        mb: 4,
        '@media screen and (max-width: 390px)': {
          mb: 3,
        },
        '.dots': {
          width: '12px',
          height: '12px',
          borderRadius: '50%',
          mx: 1,
          display: 'inline-block',
          backgroundColor: colors.primary,
          opacity: '1',
          animation: 'anim 3s infinite',
        },
        '.dots:nth-child(2)': {
          opacity: '0.2',
          animationDelay: '0.5s',
        },
        '.dots:nth-child(3)': {
          opacity: '0.2',
          animationDelay: '1s',
        },
        '.dots:nth-child(4)': {
          opacity: '0.2',
          animationDelay: '1.5s',
        },
        '.dots:nth-child(5)': {
          opacity: '0.2',
          animationDelay: '2s',
        },
        '.dots:nth-child(6)': {
          opacity: '0.2',
          animationDelay: '2.5s',
        },
        '@keyframes anim': {
          '0%': {
            opacity: '1',
          },
          '15%': {
            opacity: '1',
          },
          '100%': {
            opacity: '0.2',
          },
        },
      },
      '.subtitle-text': {
        fontSize: [2, 3],
        mb: 3,
        variant: 'text.richText',
        color: 'textGray',
        fontFamily: 'Helvetica Neue',
        textAlign: 'center',
      },
      '.rich-text': {
        variant: 'text.richText',
        px: [3, 5],
        borderBottom: `1px solid ${baseColors.gray[1]}`,
        mb: [3, 4],
        pb: [3, 4],

        ul: {
          display: 'grid',
          gridTemplateColumns: ['1fr', '1fr 1fr'],
          gap: 3,

          '@media screen and (max-width: 390px)': {
            gap: 2,

            li: {
              '&:nth-child(2), &:nth-child(3)': {
                display: 'none',
              },
            },
          },

          li: {
            listStyle: 'none',
            color: 'darkGrey',
            pl: ['30px', '35px'],
            ml: '0px',
            position: 'relative',
            fontSize: [null, 2],
            lineHeight: '150%',
            '&:before': {
              content: '""',
              position: 'absolute',
              left: '0',
              top: ['3px', '2px'],
              width: ['16px', '24px'],
              height: ['16px', '24px'],
              color: 'secondary',
              backgroundColor: 'secondary',
              mask: 'url(/images/svg/Check.svg) no-repeat 50% 50%;',
              maskSize: 'cover',
            },
          },
        },
      },
      '.payment-methods': {
        px: [3, 5],
        '> div': {
          span: {
            display: 'none',
          },
        },
      },
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: ['wrap', 'nowrap'],
      mx: 0,
      '.img-wrap': {
        width: ['100%', '532px'],
        mb: 3,
        px: 3,
        flexShrink: [null, '0'],
        alignSelf: 'center',
        '@media screen and (max-width: 390px)': {
          mb: 2,
        },
      },
      '.checkout-waiting-screen-img': {
        height: ['175px', '290px'],
        width: '100%',
        objectFit: 'cover',
        display: 'block',
        '@media screen and (max-width: 390px)': {
          height: '150px',
        },
      },
    },
    checkoutNoResults: {
      padding: ['0 8px', '0'],
      p: {
        mb: 4,
        fontFamily: '"Helvetica Neue",sans-serif',
      },
      button: {
        width: ['190px', '115px'],
        float: 'right',
        position: 'relative',
        pr: 4,
        textTransform: 'uppercase',
        '&:after': {
          content: '""',
          position: 'absolute',
          transform: 'translateY(-50%)',
          right: '12px',
          bottom: '4px',
          display: 'block',
          width: '10px',
          height: '14px',
          backgroundImage: 'url("/images/svg/chevron-long-right-white.svg")',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
        },
      },
    },
    checkoutResultsWrapper: {
      '.title:not(:empty)': {
        pt: 3,
        pb: 3,
        px: [2, 4],
        backgroundColor: '#ffe0cc',
        color: 'secondary',
        fontSize: [null, 4],
      },
      p: {
        fontSize: [null, 1],
        fontFamily: '"Helvetica Neue",sans-serif',
        px: [2, 4],
        pb: 3,
      },
      '.price-changed-text': {
        pt: 3,
        pr: [null, '50px'],
      },
      '.flight-changed-text': {
        backgroundColor: '#ffe0cc',
      },
      '.continue-booking-title': {
        backgroundColor: 'white',
        py: 2,
      },
    },
    flightSectionWrapper: {
      px: [0, 3],
      mb: 2,
    },
    checkoutResultsFlex: {
      display: 'flex',
      justifyContent: [null, 'flex-end'],
      pt: [3, 2],
      mr: [2, 4],
      ml: [2, 0],
      '.link-btn': {
        mr: 2,
        backgroundColor: 'grey',
        color: 'white',
        textTransform: 'capitalize',
        fontSize: 1,
      },
      button: {
        flex: [1, '0 0 auto'],
        backgroundColor: 'primary',
        width: [null, '175px'],
        textTransform: 'uppercase',
        position: 'relative',
        pr: 3,
        fontFamily: '"Helvetica Neue",sans-serif',
        '&:after': {
          content: '""',
          position: 'absolute',
          transform: 'translateY(-50%)',
          right: '12px',
          bottom: '4px',
          display: 'block',
          width: '10px',
          height: '14px',
          backgroundImage: 'url("/images/svg/chevron-long-right-white.svg")',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
        },
      },
    },
    checkoutWaitingScreenModal: {
      '.modal-wrapper': {
        maxWidth: [null, '970px'],
        borderRadius: 'default',
        mx: [3, 0],
        alignSelf: 'center',
        position: 'relative',
        maxHeight: '100vh',
      },
      '.modal-body': {
        pt: [2, 5],
        pb: [3, 5],
      },
      '.modal-header': {
        position: [null, 'absolute'],
        right: '0',
        border: 'none',
        pr: 3,
        pt: [3, 5],
      },
    },
    checkoutNoResultsModal: {
      '.modal-header': {
        position: [null, 'absolute'],
        right: '0',
        border: 'none',
        pr: 3,
      },
      '.modal-wrapper': {
        maxWidth: [null, '530px'],
        borderRadius: 'default',
        mx: [2, 0],
        alignSelf: 'center',
        position: 'relative',
      },
      '.modal-body': {
        pt: [2, 3],
        pb: [3, 3],
        px: [0, 4],
      },
    },
    checkoutResultsModal: {
      '.modal-header': {
        position: [null, 'absolute'],
        right: '0',
        border: 'none',
        pr: 3,
      },
      '.modal-wrapper': {
        maxWidth: [null, '600px'],
        borderRadius: 'default',
        mx: [2, 0],
        alignSelf: 'center',
        position: 'relative',
        maxHeight: '98vh',
      },
      '.modal-body': {
        pb: [3, 3],
      },
    },
  }),
  auth: sxGroup({
    b2bLoginLayout: {
      maxWidth: [null, '550px'],
      mt: 4,
      mx: 'auto',
      '.error': {
        mt: -2,
        mb: 2,
      },
    },
    errorMessage: {
      color: 'error',
      fontSize: ['14px', 0],
      mb: 2,
    },
    loginSocialButton: {
      p: 0,
      display: 'flex',
      alignItems: 'center',
      height: '40px',
      overflow: 'hidden',
      width: ['100%', '240px'],
      '.login-social-text': {
        mx: 'auto',
        fontSize: 1,
      },
      '.login-social-icon': {
        width: '40px',
        height: '40px',
        padding: '11px',
      },
    },
    loginSocialWrapper: {
      display: 'flex',
      gap: [3, '35px'],
      justifyContent: 'center',
      width: '100%',
      py: [4, '40px'],
      flexDirection: ['column', 'row'],
      alignItems: 'center',
      px: [4, 0],
    },
    loginSocialTop: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      px: [4, 0],
      '.decoration-line-span': {
        height: '1px',
        width: '100%',
        display: 'block',
        borderTop: '1px solid',
        borderColor: 'borderColor',
        flexGrow: 1,
      },
      '.or-span': {
        pt: [null, 2],
        px: [2, 3],
        color: 'secondary',
        flexShrink: '0',
        lineHeight: ['1.3', '1'],
        fontSize: [1, 2],
      },
    },
    panelContent: {
      position: 'relative',
      px: [null, 1],
    },
    b2cLoginLinkWrapper: {
      'button, a': {
        fontSize: 1,
      },
    },
    formTitleStyles: {
      fontSize: [4, '25px'],
      mb: [4, '25px'],
      fontWeight: 'normal',
    },
    formTextStyles: {
      fontSize: [2, 3],
      mb: [4, '25px'],
      fontWeight: 'normal',
    },
    loginLayoutBlockStyles: {
      maxWidth: [null, '970px'],
      backgroundColor: 'white',
      boxShadow: [null, 'blockShadow'],
      mx: [null, 'auto'],
      flexWrap: 'wrap',
      border: '1px solid',
      borderColor: 'borderColor',
      borderRadius: 'default',
      '.layout-text': {
        fontSize: [null, 1],
        mb: [null, 4],
        lineHeight: [null, '1.5'],
      },
      '.decoration-line-span + .or-span': {
        color: 'textGray',
        fontSize: 1,
      },
    },
    formModalWrapperStyles: {
      width: '100%',
      maxWidth: '450px',
      position: 'relative',
      height: [null, 'auto'],
      borderRadius: ['roundedTop', '0px'],
      '.modal-body, .modal-header': {
        backgroundColor: 'white',
      },
    },
    loginForm: {
      '.label': {
        color: 'textGray',
        fontSize: ['14px', 0],
      },
      '.text-input': {
        mb: 3,
        '&:last-of-type': {
          mb: 4,
        },
        '.input-icon': {
          display: 'none',
        },
      },
      '.login-form-submit': {
        width: '100%',
        marginBottom: [3, '20px'],
      },
      '.login-form-forgot-button': {
        py: 2,
      },
      '.error': {
        fontSize: ['14px', 0],
        mt: 1,
      },
    },
  }),
  myAccount: sxGroup({
    successMessage: {
      fontSize: '14px',
    },
    successMessageBullets: {
      fontSize: '14px',
      listStyle: 'disc',
      ml: '12px',
    },
    profile: {
      formLayoutWrapper: {
        width: '100%',
        display: 'grid',
        gap: 3,
        mt: 3,
        '.row': {
          display: 'grid',
          gridTemplateColumns: ['1fr', 'repeat(3, 1fr)'],
          gap: ['20px', 3],
          p: 0,
        },
        '.row.password': {
          gridTemplateColumns: ['1fr', 'repeat(2, 1fr)'],
        },
        '.form-field': {
          p: 0,
          '.input': {
            height: '44px',
            pl: 3,
            fontSize: '18px',
            background: 'transparent',
            color: 'black',
            cursor: 'text',
            '&:disabled': {
              background: '#f4f4f4',
              color: '#4E4E4F',
              opacity: 1,
              cursor: 'default',
            },
          },
          '.chevron': {
            width: '16px',
            height: '16px',
            bottom: '14px',
          },
          '.status-icon, .input-icon': {
            display: 'none',
          },
          '.label, .label span': {
            whiteSpace: 'nowrap',
            color: '#4E4E4F',
            p: 0,
          },
          '.error': {
            mt: 1,
            fontSize: '14px',
          },
        },
        '.form-field.half-width': {
          width: '50%',
        },
        '.form-field.salutation': {
          display: 'flex',
          flexDirection: ['column', 'row'],
          gap: ['10px', '20px'],
          '.radio-group-container': {
            gap: '20px',
            '.label': {
              m: 0,
              p: 0,
            },
          },
        },
      },
      formWrapper: {
        pb: 3,
      },
      formHeading: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        fontSize: [2, '24px'],
        lineHeight: '30px',
        mt: [3, 5],
        py: '12px',
        color: 'secondary',
      },
      infoText: {
        width: '100%',
        maxWidth: '800px',
        color: 'textGray',
        fontSize: 1,
        fontFamily: '"Arial", sans-serif',
        mt: 1,
      },
      submitButton: {
        bg: 'transparent',
        p: '8px 24px',
        width: ['100%', 'fit-content'],
        border: '1px solid',
        borderColor: 'black',
        borderRadius: '4px',
        justifySelf: 'end',
        '.button-text': {
          color: 'black',
          fontSize: '18px',
          fontFamily: '"Arial",sans-serif',
        },
        '&:hover': {
          bg: 'black',
          '.button-text': {
            color: 'white',
          },
        },
      },
      actionButton: {
        display: 'flex',
        alignItems: 'center',
        flexShrink: 0,
        gap: 2,
        width: ['100%', 'auto'],
        padding: ['9px 10px', '7px 10px'],
        marginLeft: 'auto',
        backgroundColor: 'primary',
        fontFamily: '"Helvetica Neue", sans-serif',
        fontWeight: '400',
        fontSize: 1,
        letterSpacing: '0.3px',
        textTransform: 'uppercase',
        textAlign: 'center',
        whiteSpace: ['normal', 'nowrap'],
        color: 'white',
        '.button-text': {
          width: '100%',
        },
        '.icon': {
          ml: 'auto',
          order: 1,
          width: '14px',
          height: '14px',
        },
      },
      externalAuthMessageWrapper: {
        maxWidth: '800px',
        color: 'textGray',
        fontSize: 1,
        fontFamily: '"Arial", sans-serif',
        lineHeight: '24px',
        a: {
          color: 'primary',
          '&:hover': {
            textDecoration: 'underline',
          },
        },
      },
    },
  }),
  tripAdvisor: sxGroup({
    small: {
      icon: {
        width: ['17px', '15px'],
        height: ['17px', '15px'],
      },
      image: {
        objectFit: 'contain',
        height: '18px',
        width: ['28px', '23px'],
        [media.tablet]: {
          height: '27px',
          width: '45px',
        },
        [media.desktop]: {
          height: '27px',
          width: '45px',
        },
      },
      text: {
        fontWeight: 'normal',
      },
    },
    medium: {
      icon: {
        width: '16px',
        height: '16px',
      },
      image: {
        height: '30px',
        width: '50px',
        [media.tablet]: {
          height: '35px',
          width: '60px',
        },
        [media.desktop]: {
          height: '35px',
          width: '60px',
        },
      },
      text: {
        fontWeight: 'bold',
      },
    },
    detailsWrapper: {
      py: [4, '65px'],
      mx: [4, 0],
      '> .-info': {
        mb: 2,
      },
      '.-info-wrapper': {
        mb: [2, 3],
      },
    },
    detailsInfo: {
      flexDirection: ['column', 'row'],
      alignItems: [null, 'center'],
      fontSize: [null, '25px'],
      '.icon': {
        variant: ['icons.md', 'icons.lg'],
        mr: [null, 2],
      },
      '.tripAdvisorInfoText': {
        pt: ['13px', 0],
        ml: [null, 4],
      },
      '.tripAdvisorInfoNumber': {
        fontWeight: 'bold',
      },
    },
    detailedReview: {
      marginBottom: space[2],
      padding: `${space[3]} 0`,
      borderTop: '1px solid',
      borderBottom: '1px solid',
      borderColor: colors.borderColor,
      [media.tablet]: {
        marginBottom: space[4],
      },
      '> h2': {
        fontSize: [2, '25px'],
        mb: ['10px', 3],
      },
      '> .rating-wrap': {
        display: 'flex',
        flexDirection: ['column', 'row'],
        flexWrap: 'wrap',
        gap: [null, '5px 0'],
      },
    },
    currentRatings: {
      '> h2': {
        fontSize: [2, '25px'],
        mb: [4, '45px'],
      },
    },
    detailsRating: {
      mr: [null, 5],
      mb: ['12px', 0],
      lineHeight: ['1.1', '1.4'],
      '.icon': {
        variant: [null, 'icons.md'],
        mr: [null, '6px'],
      },
    },
    ratingWrap: {
      display: 'flex',
      position: 'relative',
      alignItems: 'center',
      [media.tablet]: {
        fontSize: fontSizes[3],
      },
    },
    copyrights: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      fontSize: fontSizes[1],
      [media.tablet]: {
        fontSize: fontSizes[2],
      },
      '> .-year': {
        paddingRight: space[2],
        [media.tablet]: {
          paddingRight: space[1],
        },
      },
      '> .-powered': {
        display: 'flex',
        alignItems: 'center',

        '> .-powered-text': {
          marginRight: space[2],
        },
      },
    },
    logo: {
      height: '20px',
      width: '120px',
    },
    reviewHeader: {
      width: ['100%', `${(3 / 10) * 100}%`],
      mb: [2, 1],
      pr: [2, 3],
      '.review-description': {
        fontSize: [1, 3],
        color: 'secondary',
        pt: 2,
        mb: 1,

        '.review-author, .review-region, .review-date': {
          '&:not(:last-child):after': {
            display: 'inline-block',
            content: '"/"',
            pl: 1,
            mr: 1,
          },
        },
      },
    },
    moreLessButton: {
      fontSize: [2, 3],
    },
    commentTitle: {
      fontSize: [2, 3],
      mb: [2, 3],
    },
    commentText: {
      fontSize: [null, 3],
      letterSpacing: [null, '0.7px'],
      whiteSpace: 'pre-wrap',
      mb: 1,
    },
  }),
  map: {
    pinWrapper: {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: '50%',
      transform: 'translate(-50%, 0)',
    },
    pinContent: {
      position: 'absolute',
      bottom: '6px',
      transform: 'translate(-50%, 0)',
      padding: '6px 8px',
      cursor: 'pointer',
      boxShadow: shadows.cardShadow,
      color: 'white',
      fontSize: fontSizes[1],
      [media.tablet]: {
        fontSize: fontSizes[2],
      },
    },
  },
  mapRadiusSearch: sxGroup({
    autocompleteControls: {
      display: 'flex',
      flexDirection: ['column', 'row'],
      gap: [3, 0],
      fontSize: 2,
      alignItems: 'center',
      '.map-autocomplete': {
        width: '100%',
        '.input': {
          pl: 2,
          height: '40px',
          fontSize: 1,
          bg: 'white',
          [media.tablet]: {
            borderRadius: '5px 0px 0px 5px',
            borderRight: 'none',
            fontSize: '14px',
          },
        },
      },
    },
    radiusInput: {
      width: '100%',
      minWidth: '100px',
      flex: '1 1 0%',
      '.input': {
        pl: 2,
        height: '40px',
        fontSize: 1,
        [media.tablet]: {
          borderRadius: 'unset',
          fontSize: '14px',
        },
      },
      '.icon': {
        bottom: '16px',
      },
    },
    pin: {
      '.map-pin-content': {
        backgroundColor: 'red',
        border: '1px solid',
        borderColor: 'white',
        boxShadow: '0px 0px 5px 0px darkgray',
        '.point-name': {
          fontSize: '14px',
        },
      },
      '.map-pin-wrapper:after': {
        position: 'absolute',
        display: 'block',
        content: '',
        width: '0',
        height: '0',
        borderLeft: '10px solid transparent',
        borderRight: '10px solid transparent',
        borderTop: '10px solid red',
        left: '50%',
      },
    },
    locationIcon: {
      display: 'inline-block',
      pr: 2,
      my: -4,
    },
  }),
  srl: sxGroup({
    accordionItem: {
      overflow: 'hidden',
      mb: 10,
      border: '1px solid',
      borderColor: 'borderGray',
      borderRadius: [0, 'default'],
      mr: [null, '-1px'],
      [media.mobile]: {
        borderLeftStyle: 'none',
        borderRightStyle: 'none',
      },

      '.accordionButtonWrap': {
        p: 0,
        pr: 1,
        backgroundColor: 'white',
      },

      '&:not(.active)': {
        '.accordionButtonWrap': {
          '&:hover': {
            backgroundColor: 'hoverGray',
          },
        },
      },

      '.accordionButton': {
        display: 'flex',
        flexDirection: 'row-reverse',
        alignItems: 'center',
        width: '100%',
        flex: 1,
        border: 'none',
        px: 2,
        py: [2, 1],
        fontWeight: 'bold',
      },

      '.accordionButtonWrap .icon': {
        transform: 'rotate(0deg)',
        ml: 0,
        mr: 2,
        height: '20px',
        color: 'textGray',
      },

      '.accordionContent': {
        backgroundColor: 'backgroundGray',
      },

      '&.active': {
        '.accordionContent': {
          borderTopWidth: '1px',
          borderTopColor: 'borderGray',
          borderTopStyle: 'solid',
        },
      },
    },
  }),
  mediaItem: sxGroup({
    cardContent: {
      px: 4,
      pb: ['30px', 4],
      color: 'white',
      position: 'absolute',
      bottom: 0,
      width: '100%',
      textAlign: 'center',
      '.card-title': {
        fontSize: [3, '30px'],
      },
      '.card-subtitle': {
        fontSize: [2, 3],
      },
      '.wishlist-icon': {
        position: 'absolute',
        right: '16px',
        top: '16px',
        width: '26px',
        height: '26px',
        color: 'white',
        cursor: 'pointer',
      },
      '.button-text': {
        display: 'none',
      },
    },
  }),
  lastSeenHotels: sxGroup({
    lastSeenHotelsWrapper: {
      pb: 5,
    },
    lastSeenHotelsTitle: {
      fontSize: [2, '26px'],
      my: 3,
      ml: [3, 0],
    },
    lastSeenHotelsCards: {
      display: `grid`,
      gridTemplateColumns: ['1fr 1fr', `repeat(3, 315px)`],
      gap: ['25px 15px', '35px 18px'],
      gridAutoFlow: [null, 'row'],
    },
    lastSeenHotelItemRating: {
      marginBottom: 1,
      color: 'secondary',
      gap: '2px',
      '.rating .icon': {
        width: ['12px', '17px'],
        height: ['12px', '17px'],
        mr: 0,
      },
    },
    lastSeenHotelItemImage: {
      width: '100%',
      overflow: 'hidden',
      objectFit: 'cover',
      height: '170px',
    },
    lastSeenHotelItemCard: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: 'white',
      border: '1px solid',
      borderColor: '#999',
      height: '100%',
      cursor: 'pointer',
      ':hover': {
        color: 'inherit',
      },
      img: {
        cursor: 'pointer',
      },
    },
    lastSeenHotelItemWrap: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    lastSeenHotelItemContent: {
      padding: ['8px 10px', '8px 12px'],
      color: 'textGray',
      fontSize: ['12px', 1],
      fontWeight: 'bold',
      overflow: 'hidden',
      width: '100%',
      '.title': {
        fontSize: ['14px', '18px'],
        fontWeight: 'bold',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        color: 'secondary',
        '@media screen and (max-width: 520px)': {
          maxWidth: '150px',
        },
        maxWidth: ['230px', '289px'],
      },
    },
  }),
});

export default theme;
