import { useTranslation } from 'next-i18next';
import React, { useRef } from 'react';
import { DateUtils } from 'react-day-picker';
import styled from 'styled-components';
import {
  Configuration,
  ITravelRoomsModalProps,
  TravelRoomsDropdown,
  TravelRoomsField,
  useTravelRooms,
  MAX_TRAVELLERS_DEFAULT,
  MAX_TRAVELLERS_FOR_FOUR_ROOMS,
} from '@hotelplan/components.common.travel-rooms';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useFeatureToggle } from '@hotelplan/libs.feature-toggle';
import { useOnClickOutside } from '@hotelplan/libs.hooks-dom';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import { calcFormFieldInputValue } from 'components/domain/srl/CurrentSearchContext';
import { FeatureList } from 'config/pageConfig.constants';
import DayPickerComponent from './DayPickerComponent';

const TravelRoomsWrapper = styled.div(
  sx2CssThemeFn({
    '.travel-rooms-dropdown': {
      width: [null, '535px'],
    },
  })
);

const mockedInfoData = {
  number: '0800 88 88 12',
};

interface ITravelRoomsContainerProps {
  label?: React.ReactNode;
}

export default function TravelRoomsContainer({
  label,
}: ITravelRoomsContainerProps): React.ReactElement {
  const [t] = useTranslation('search');
  const { mobile } = useDeviceType();

  const { getVariant } = useFeatureToggle();
  const isSearchForFourRoomsEnabled =
    getVariant(FeatureList.MAX_SEARCHING_ROOMS)?.name === 'variant-B';

  const {
    value: travelRooms,
    setValue: setTravelRooms,
    isValid,
  } = useTravelRooms(isSearchForFourRoomsEnabled);

  const [isFieldOpened, , closeField, toggleField] = useToggleState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const textInputRef = useRef<HTMLInputElement>(null);

  const textInputValue = calcFormFieldInputValue('travelRooms', travelRooms, t);

  useOnClickOutside<HTMLDivElement | HTMLInputElement>(
    [dropdownRef, textInputRef],
    () => {
      if (!isValid || mobile) return;
      closeField();
    },
    isFieldOpened
  );

  const configuration: Configuration = {
    hasApplyBtn: !mobile,
    hasCloseBtn: true,
    infoNotification: {
      enable: true,
      notificationText: t('rooms.info', {
        number: mockedInfoData.number,
        maxTravellers: isSearchForFourRoomsEnabled
          ? MAX_TRAVELLERS_FOR_FOUR_ROOMS
          : MAX_TRAVELLERS_DEFAULT,
      }),
    },
    isSearchForFourRoomsEnabled,
    dobPicker: {
      enabled: true,
      component: ({ dateFromField, onChange, onClose }) => {
        return (
          <DayPickerComponent
            defaultDate={
              (DateUtils.isDate(dateFromField) && dateFromField) || new Date()
            }
            onClose={onClose}
            onChange={onChange}
          />
        );
      },
    },
    isIOSDobInputDisabled: true,
  };

  const travelRoomsProps: ITravelRoomsModalProps = {
    travelRooms,
    configuration,
    saveTravelRooms: function (nextTravelRooms) {
      setTravelRooms(nextTravelRooms);
      configuration.hasApplyBtn && closeField();
    },
    show: isFieldOpened,
    close: closeField,
    isManualDistribution: true,
  };

  return (
    <TravelRoomsWrapper>
      <TravelRoomsField
        inputRef={textInputRef}
        inputValue={textInputValue}
        inputIcon={{ name: 'home' }}
        onClick={toggleField}
        customLabel={label}
      >
        <TravelRoomsDropdown
          dropdownRef={dropdownRef}
          {...travelRoomsProps}
          className={'dropdown-wrapper'}
        />
      </TravelRoomsField>
    </TravelRoomsWrapper>
  );
}
