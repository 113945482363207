export enum FeatureList {
  MEIN_KONTO = 'mein-konto-features',
  MY_ACCOUNT_UPSELL_TRANSFERS = 'myaccount-upsell-transfers',
  MF_LAST_SEEN_HOTELS = 'mf-last-seen-hotels',
  SEARCH_CONTROL_VARIANT = 'mf-search-control-variant',
  SMARTSEER_SORTING = 'mf-smartseer-sorting',
  PDP_IMAGE_GALLERY_POSITION = 'mf-pdp-image-gallery-position',
  SPLIT_PAGEVIEW_EVENT = 'split-pageview-event',
  MAX_SEARCHING_ROOMS = 'mf-max-searching-rooms',
}
